import Vue from 'vue';
import { GetMember } from '@/api/member'
import { GetBusiness, GetBusinessConfig } from '@/api/business'
import { GetStore, GetStoreConfig } from '@/api/store'
import { GetDesk } from '@/api/baseinfo'
import DSSKData from '../modules/DSSKData.json'
import moment from 'moment'

export default {
    data() {
        return {
            _memberCode: null,
            _member: null,
            _activeBusinessCode: null,
            _activeBusiness: null,
            _activeBusinessConfig: null,
            _activeStoreCode: null,
            _activeStore: null,
            _activeStoreConfig: null,
            _activeDesk: null,
            _activeDeskID: null,
            _activeDeskCode: null,
            _activeDeskQrCode: null,

            ActivityStartTime: '2024-10-01 00:00',
            ActivityEndTime: '2024-10-01 18:00',
            routeQuery: {},
        };
    },
    computed: {
        OnActivity() {
            // if((this.member.MemberCode == "o0Hzev32lXbvX3womtVQOFH6t1A8" || this.member.MemberCode == "744d7401a63043918a897b9d627dc750"))
            // {
            //     return true;
            // }
            return moment() > moment(this.ActivityStartTime) && moment(new Date()) < moment(this.ActivityEndTime) && this.member?.BusinessCode == 'c5e6baa9861e452dbd420bc16721e474';
        },
        IsDebug() {
            console.log('IsDebug', document.domain, document.domain.indexOf('localhost'), document.domain.indexOf('192.168'))
            return document.domain.indexOf('localhost') >= 0 || document.domain.indexOf('192.168') >= 0 ? true : false;
        },
        StoredDescriptionInfo() {
            var StoredDescriptions =
              this.BusinessConfig?.AdsenseConfig?.StoredDescriptions;
            if(StoredDescriptions==null){
                return '';
            }
            // 初始化为1、2、3的数组
            var array = [
              StoredDescriptions[1],
              StoredDescriptions[2],
              StoredDescriptions[3],
            ];
            // 打乱数组
            for (let i = array.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [array[i], array[j]] = [array[j], array[i]]; // 交换元素
              }
            // 因为需要全部三个数字，且已经打乱，直接返回打乱后的数组
            StoredDescriptions[1] = array[0];
            StoredDescriptions[2] = array[1];
            StoredDescriptions[3] = array[2];
      
            var outputStr = "";
            StoredDescriptions.forEach((o) => (outputStr += o));
            return outputStr;
          },
      
        memberCode: {
            get() {
                if (this._memberCode) { return this._memberCode; }
                return Vue.ls.get('MemberCode');
            },
            set(value) {
                console.log('memberCode.set', value)
                this._memberCode = value;
                if (value) { Vue.ls.set('MemberCode', value); }
                else { Vue.ls.remove('MemberCode'); }
            }
        },
        member: {
            get() {
                if (this._member) { return this._member; }
                return Vue.ls.get(Vue.ls.get('MemberCode') + '_Member');
            },
            set(value) {
                if (value) {
                    value.loadTime = new Date();
                    this._member = value;
                    Vue.ls.set(Vue.ls.get('MemberCode') + '_Member', value);
                }
                else { Vue.ls.remove(Vue.ls.get('MemberCode') + '_Member'); }
            }
        },
        activeBusinessCode: {
            get() {
                if (this._activeBusinessCode) { return this._activeBusinessCode; }
                return Vue.ls.get(Vue.ls.get('MemberCode') + '_BusinessCode');
            },
            set(value) {
                if (value) {
                    this._activeBusinessCode = value;
                    Vue.ls.set(Vue.ls.get('MemberCode') + '_BusinessCode', value);
                }
                else { Vue.ls.remove(Vue.ls.get('MemberCode') + '_BusinessCode'); }
            }
        },
        activeBusiness: {
            get() {
                if (this._activeBusiness) { return this._activeBusiness; }
                return Vue.ls.get(Vue.ls.get('MemberCode') + '_Business');
            },
            set(value) {
                if (value) {
                    value.loadTime = new Date();
                    this._activeBusiness = value;
                    Vue.ls.set(Vue.ls.get('MemberCode') + '_Business', value);
                }
                else { Vue.ls.remove(Vue.ls.get('MemberCode') + '_Business'); }
            }
        },
        activeBusinessConfig: {
            get() {
                if (this._activeBusinessConfig) { return this._activeBusinessConfig; }
                return Vue.ls.get(Vue.ls.get('MemberCode') + '_BusinessConfig');
            },
            set(value) {
                if (value) {
                    value.loadTime = new Date();
                    this._activeBusinessConfig = value;
                    Vue.ls.set(Vue.ls.get('MemberCode') + '_BusinessConfig', value);
                }
                else { Vue.ls.remove(Vue.ls.get('MemberCode') + '_BusinessConfig'); }
            }
        },
        activeStoreCode: {
            get() {
                if (this._activeStoreCode) { return this._activeStoreCode; }
                return Vue.ls.get(Vue.ls.get('MemberCode') + '_StoreCode');
            },
            set(value) {
                if (value) {
                    this._activeStoreCode = value;
                    Vue.ls.set(Vue.ls.get('MemberCode') + '_StoreCode', value);
                }
                else { Vue.ls.remove(Vue.ls.get('MemberCode') + '_StoreCode'); }
            }
        },
        activeStore: {
            get() {
                if (this._activeStore) { return this._activeStore; }
                return Vue.ls.get(Vue.ls.get('MemberCode') + '_Store');
            },
            set(value) {
                if (value) {
                    value.loadTime = new Date();
                    this._activeStore = value;
                    Vue.ls.set(Vue.ls.get('MemberCode') + '_Store', value);
                }
                else { Vue.ls.remove(Vue.ls.get('MemberCode') + '_Store'); }
            }
        },
        activeStoreConfig: {
            get() {
                if (this._activeStoreConfig) { return this._activeStoreConfig; }
                return Vue.ls.get(Vue.ls.get('MemberCode') + '_StoreConfig');
            },
            set(value) {
                if (value) {
                    value.loadTime = new Date();
                    this._activeStoreConfig = value;
                    Vue.ls.set(Vue.ls.get('MemberCode') + '_StoreConfig', value);
                }
                else { Vue.ls.remove(Vue.ls.get('MemberCode') + '_StoreConfig'); }
            }
        },
        activeDeskID: {
            get() {
                if (this._activeDeskID) { return this._activeDeskID; }
                return Vue.ls.get(Vue.ls.get('MemberCode') + '_DeskID');
            },
            set(value) {
                if (value) {
                    this._activeDeskID = value;
                    Vue.ls.set(Vue.ls.get('MemberCode') + '_DeskID', value);
                }
                else { Vue.ls.remove(Vue.ls.get('MemberCode') + '_DeskID'); }
            }
        },
        activeDeskCode: {
            get() {
                if (this._activeDeskCode) { return this._activeDeskCode; }
                return Vue.ls.get(Vue.ls.get('MemberCode') + '_DeskCode');
            },
            set(value) {
                if (value) {
                    this._activeDeskCode = value;
                    Vue.ls.set(Vue.ls.get('MemberCode') + '_DeskCode', value);
                }
                else { Vue.ls.remove(Vue.ls.get('MemberCode') + '_DeskCode'); }
            }
        },
        activeDeskQrCode: {
            get() {
                if (this._activeDeskQrCode) { return this._activeDeskQrCode; }
                return Vue.ls.get(Vue.ls.get('MemberCode') + '_DeskQrCode');
            },
            set(value) {
                if (value) {
                    this._activeDeskQrCode = value;
                    Vue.ls.set(Vue.ls.get('MemberCode') + '_DeskQrCode', value);
                }
                else { Vue.ls.remove(Vue.ls.get('MemberCode') + '_DeskQrCode'); }
            }
        },
        activeDesk: {
            get() {
                if (this._activeDesk) { return this._activeDesk; }
                return Vue.ls.get(Vue.ls.get('MemberCode') + '_Desk');
            },
            set(value) {
                if (value) {
                    value.loadTime = new Date();
                    this._activeDesk = value;
                    Vue.ls.set(Vue.ls.get('MemberCode') + '_Desk', value);
                }
                else { Vue.ls.remove(Vue.ls.get('MemberCode') + '_Desk'); }
            }
        },

        loadState() {
            if (this.member
                && this.activeBusiness && this.activeBusinessConfig
                && ((!this.activeStoreCode && !this.routeQuery.StoreCode) || this.activeStore && this.activeStoreConfig)
                && (
                    (!this.activeDeskID && !this.routeQuery.DeskID)
                    && (!this.activeDeskCode && !this.routeQuery.DeskCode)
                    && (!this.activeDeskQrCode && !this.routeQuery.DeskQrCode)
                    || this.activeDesk
                )
            ) {
                return true;
            }
            return false;
        },
        memberDiscountSwitch() {
            return this.activeStoreConfig?.OrderConfig.MemberDiscountSwitch == 1;
        },
        integralCheckSwitch() {
            return this.activeStoreConfig?.OrderConfig.IntegralCheckSwitch == 1;
        },
    },
    watch: {
    },
    methods: {


  

        async initData() {                        
            
            this.activeBusinessCode = null;
            this.activeStoreCode = null;
            this.activeDeskID = null;
            this.activeDeskCode = null;
            this.activeDeskQrCode = null;

            // Vue.delete(this, 'member');
            // Vue.delete(this, 'activeBusiness');
            // Vue.delete(this, 'activeBusinessConfig');
            this.member = null;
            this.activeBusiness = null;
            this.activeBusinessConfig = null;

            this.activeStore = null;
            this.activeStoreConfig = null;
            this.activeDesk = null;
            this.memberCode = null;
            // 等待下一个Vue.js更新周期
            await this.$nextTick();
            console.log('3333333', Vue.ls.get('MemberCode'), this.memberCode)
        },
        async loadData(query) {
                console.log('loadData', query)
                this.routeQuery = query;
                var __member=this.member;
                try {
                    console.log('this.memberCode', this.memberCode, this._memberCode, query.MemberCode,this.member)
                    if (query.MemberCode || !this.member || this.member.MemberCode != this._memberCode) {
                        if (!query.MemberCode) {
                            query.MemberCode = this._memberCode ? this._memberCode : this.memberCode;
                        }
                        this.memberCode=query.MemberCode;
                        await this.loadMember(query.MemberCode,query).then(res => {
                            console.log('loadMember.then', res, this._member, this.member)
                            console.log(252);
                            __member=res;
                            if (!res) {
                                console.error('会员不存在！')
                                // this.$router.push({ name: 'TimeOut', msg: '会员不存在！' });
                                return;
                            }
                        })
                        // 使用 Promise.all 等待所有异步函数完成
                        console.log('member.info',__member, this._member, this.member)
                        await Promise.all([
                            this.loadActiveBusiness(__member.BusinessCode),
                            this.loadActiveBusinessConfig(__member.BusinessCode),
                        ]).then(()=>{
                            console.log(123456789)
                            // console.log('loadActiveBusiness.then',  this._activeBusiness, this.activeBusiness, this._activeBusinessConfig, this.activeBusinessConfig)
                            if(this.activeStore?.BusinessCode!=__member.BusinessCode){
                                this.activeStore=null;
                                this.activeStoreConfig=null;
                                this.activeStoreCode=null;

                                this.activeDesk=null;
                                this.activeDeskID=null;
                                this.activeDeskCode=null;
                                this.activeDeskQrCode=null;
                            }
                        });
                        
                    }
                    console.log(123456789)

                    if (query.StoreCode || ((!this.activeStore || !this.activeStoreConfig) && this.activeStoreCode)) {
                        if (!query.StoreCode) {
                            query.StoreCode = this.activeStoreCode;
                        }
                        await Promise.all([
                            this.loadActiveStore(query.StoreCode),
                            this.loadActiveStoreConfig(query.StoreCode),
                        ]).then(()=>{
                            console.log('loadActiveStore.then')
                            if(this.activeDesk?.StoreCode != query.StoreCode){
                                this.activeDesk=null;
                                this.activeDeskID=null;
                                this.activeDeskCode=null;
                                this.activeDeskQrCode=null;
                            }
                        });
                    }
                    if (query.DeskID || query.DeskCode || query.DeskQrCode || (!this.activeDesk && (this.activeDeskID || this.activeDeskCode || this.activeDeskQrCode))) {
                        if (!query.DeskID && !query.DeskCode && !query.DeskQrCode) {
                            query.DeskID = this.activeDeskID;
                            query.DeskCode = this.activeDeskCode;
                            query.DeskQrCode = this.activeDeskQrCode;
                        }
                        await Promise.all([
                            this.loadActiveDesk(query.StoreCode ? query.StoreCode : this.activeStoreCode, query)
                        ]);
                    }
                    // console.log(303,this._member,this._activeBusiness,this._activeBusinessConfig
                    //     ,((!this.activeStoreCode && !this.routeQuery.StoreCode) || this.activeStore && this.activeStoreConfig)
                    //     ,(
                    //         (!this.activeDeskID && !this.routeQuery.DeskID)
                    //         && (!this.activeDeskCode && !this.routeQuery.DeskCode)
                    //         && (!this.activeDeskQrCode && !this.routeQuery.DeskQrCode)
                    //         || this.activeDesk
                    //     ));
                    // return this.loadState;
                    return true;
                    // 所有的异步函数都完成后的代码
                    console.log('所有数据加载完毕');
                } catch (error) {
                    console.error('加载过程中发生错误:', error);
                    // 处理错误，可能是跳转到错误页面或者显示错误信息
                }
                finally{
                    console.log('loadData.finally',this.loadState)
                    // return this.loadState;
                    console.log('onLoadData.this.member', __member)
                    if (__member?.FrozenState == 1 && this.$route.name != 'FrozenPage') {
                        this.$router.push({ name: 'FrozenPage',query:{NickName:__member.NickName,MemberNumber:__member.MemberNumber} });
                    }
                }
        },
        async loadMember(memberCode,query) {
            return new Promise((resolve, reject) => {
                try {
                    console.log('loadMember', memberCode, this._memberCode);
                    if (this.member && this.member.MemberCode == memberCode && new Date() - new Date(this.member?.loadTime) < 1000) {
                        console.log('318',this.member)
                        resolve(this.member);
                        return;
                    }
                    console.log('321',this.member)
                    GetMember({ MemberCodeOrOpenID: memberCode,query:query })
                        .then(res => {
                            if (res.state == 200) {
                                this.member = res.data;
                                this.memberCode = res.data?.MemberCode;
                                resolve(res.data);
                            }
                            else {
                                console.error("loadMember.error", res);
                                this.member = null;
                                reject(null);
                            }
                        }).catch(error => {
                            console.error("loadMember.catch", error);
                            this.member = null;
                            reject(null);
                        }).finally(res => {
                            console.log('GetMember.finally')
                        });
                } catch (error) {
                    console.error(error);
                } finally {
                    return this.member;
                };
            });
        },
        async reloadMember() {
            console.log('reloadMember')
            this.loadMember(this.memberCode).then(res => {
                return res;
            })
        },
        async loadActiveBusiness(businessCode) {
            return new Promise((resolve, reject) => {
                try {
                    console.log('OnActivity', this.OnActivity)
                    if (this.OnActivity) {
                        this.activeBusiness = DSSKData.Business;
                        this.activeBusinessCode = DSSKData.Business?.BusinessCode;
                        resolve(DSSKData.Business)
                        return;
                    }
                    if (this.activeBusiness && this.activeBusiness?.BusinessCode == businessCode && new Date() - new Date(this.activeBusiness?.loadTime) < 1000) {
                        resolve(this.activeBusiness)
                        return
                    }
                    console.log('GetBusiness')
                    GetBusiness({ BusinessCode: businessCode })
                        .then(res => {
                            if (res.state == 200) {
                                this.activeBusiness = res.data;
                                this.activeBusinessCode = res.data?.BusinessCode
                                resolve(res.data);
                            } else {
                                console.error("loadActiveBusiness.error", res);
                                this.activeBusiness = null;
                                reject(null);
                            }
                        }).catch(error => {
                            console.error("loadActiveBusiness.catch", error);
                            reject(null);
                        }).finally(res => {
                            console.log('loadActiveBusiness.finally')
                            reject(null);
                        });
                } catch (error) {
                    console.error(error);
                    reject(null);
                }
            });
        },
        async loadActiveBusinessConfig(businessCode) {
            return new Promise((resolve, reject) => {
                try {
                    console.log('OnActivity', this.OnActivity)
                    if (this.OnActivity) {
                        this.activeBusinessConfig = DSSKData.BusinessConfig;
                        resolve(this.activeBusinessConfig );
                        return;
                    }
                    if (this.activeBusinessConfig && this.activeBusinessConfig?.BusinessCode == businessCode && new Date() - new Date(this.activeBusinessConfig?.loadTime) < 1000) {
                        return
                    }
                    console.log('GetBusinessConfig')
                    GetBusinessConfig({ BusinessCode: businessCode })
                        .then(res => {
                            if (res.state == 200) {
                                this.activeBusinessConfig = res.data;
                                resolve(res.data);
                            } else {
                                console.error("loadActiveBusinessConfig.error", res);
                                this.activeBusinessConfig = null;
                                reject(null);
                            }
                        }).catch(error => {
                            console.error("loadActiveBusinessConfig.catch", error);
                            reject(null);
                        }).finally(res => {
                            console.log('loadActiveBusinessConfig.finally')
                        });
                } catch (error) {
                    console.error(error);
                    reject(null);
                }
            });
        },
        async loadActiveStore(storeCode) {
            return new Promise((resolve, reject) => {
                try {
                    if (this.activeStore && this.activeStore?.StoreCode == storeCode && new Date() - new Date(this.activeStore?.loadTime) < 1000) {
                        return
                    }
                    GetStore({ StoreCode: storeCode })
                        .then(res => {
                            if (res.state == 200) {
                                this.activeStore = res.data;
                                this.activeStoreCode = res.data?.StoreCode;
                                resolve(res.data);
                            } else {
                                console.error("loadactiveStore.error", res);
                                this.activeStore = null;
                                reject(null);
                            }
                        }).catch(error => {
                            console.error("loadactiveStore.catch", error);
                            reject(null);
                        }).finally(res => {
                            console.log('loadactiveStore.finally')
                        });
                } catch (error) {
                    console.error(error);
                    reject(null);
                }
            });
        },
        async loadActiveStoreConfig(storeCode) {
            return new Promise((resolve, reject) => {
                try {
                    if (this.activeStoreConfig && this.activeStoreConfig?.StoreCode == storeCode && new Date() - new Date(this.activeStoreConfig?.loadTime) < 1000) {
                        return
                    }
                    GetStoreConfig({ StoreCode: storeCode })
                        .then(res => {
                            if (res.state == 200) {
                                this.activeStoreConfig = res.data;
                                resolve(res.data);
                            } else {
                                console.error("loadActiveStoreConfig.error", res);
                                this.activeStoreConfig = null;
                                reject(null);
                            }
                        }).catch(error => {
                            console.error("loadActiveStoreConfig.catch", error);
                            reject(null);
                        }).finally(res => {
                            console.log('loadActiveStoreConfig.finally')
                        });
                } catch (error) {
                    console.error(error);
                    reject(null);
                }
            });
        },
        async loadActiveDesk(storeCode, query) {
            return new Promise((resolve, reject) => {
                try {
                    if (this.activeDesk
                        && (this.activeDesk?.StoreCode == storeCode || this.activeDesk?.DeskID == query.DeskID || this.activeDesk?.QrCode == query.QrCode || this.activeDesk?.DeskID == query.DeskCode)
                        && new Date() - new Date(this.activeDesk?.loadTime) < 1000) {
                        return
                    }
                    GetDesk({
                        StoreCode: storeCode,
                        DeskID: query.DeskID,
                        DeskCode: query.DeskCode,
                        QrCode: query.DeskQrCode
                    })
                        .then(res => {
                            if (res.state == 200) {
                                this.activeDesk = res.data;
                                // console.log('this.activeDesk', this.activeDesk)
                                this.activeDeskCode = res.data?.DeskCode;
                                this.activeDeskID = res.data?.DeskID;
                                this.activeDeskQrCode = res.data?.QrCode;
                                resolve(res.data);
                            } else {
                                console.error("loadActiveDesk.error", res);
                                this.activeDesk = null;
                                reject(null);
                            }
                        }).catch(error => {
                            console.error("loadActiveDesk.catch", error);
                            reject(null);
                        }).finally(res => {
                            console.log('loadActiveDesk.finally')
                        });
                } catch (error) {
                    console.error(error);
                    reject(null);
                }
            });
        },


    }
};
