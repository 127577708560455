<template>
    <transition name="fade">
        <div class="diner-num">
            <div class="diner-num-cot" >
                <h6 class="title" style="font-size: 18px;">温馨提示</h6>
                <div  style="padding: 15px 0;text-indent: 2em; line-height: 1.5em;">
                  欢迎您您成为我们的vip客户，为了保障您的储值合法权益，请你仔细阅读<router-link :to="{name:'storedProtocolInfo'}" style="color: #191254;font-weight: bold;">《储值协议》</router-link>，我们将确保你的储值权益。
                  <!-- 进入应用前，你需先同意<router-link :to="{name:'storedProtocolInfo'}" style="color: #191254;font-weight: bold;">《大圣储值服务协议》</router-link>，否则将退出应用。 -->
                </div>
                <div style=" font-weight: bold;">
                    <div style="width: 50%;float: left;line-height: 50px;text-align: center;font-weight: bold;" @click="onCancel()">退出应用</div>
                    <div style="width: 50%;float: left;line-height: 50px;text-align: center;font-weight: bold;" @click="onConfirm()">同意并继续</div>
                </div>
            </div>
        </div>
    </transition>    
</template>

<script>
import { Toast, Indicator } from "mint-ui";
import { ChangeStoredProtocalState } from "@/api/member";
export default {
  name: "storedProtocol",
  props: {
    MemberCode: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  methods:{
    onConfirm(){
        
        var paramer={
            MemberCodeOrOpenID:this.MemberCode,
            IsAgreeStoredProtocol:1
        };
        ChangeStoredProtocalState(paramer)
          .then((res) => {
            if (res.state == 200) {
                this.OnToast("已同意协议");
                this.$emit('onConfirm');
            } else {
                this.OnToast("网络开小差了QAQ，请待会再试试吧~");
            }
          })
          .catch((res) => {
            this.OnToast("网络开小差了QAQ，请待会再试试吧~");
          })
          .finally((res) => {});
    },

    onCancel(){
        WeixinJSBridge.call('closeWindow');
        document.addEventListener('WeixinJSBridgeReady', function(){ WeixinJSBridge.call('closeWindow'); }, false);
    },

    OnToast(message) {
      Toast({
        message,
        position: "top",
        duration: 2000,
      });
    },
  }
};
</script>

<style scoped>
.popupinput.cot {
  width: 102px;
}
.active .popupinput.cot::placeholder {
  /* 设置字体颜色 */
  color: white; /* 将颜色修改为所需的颜色 */
}
.diner-num {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99;
  transition: all 0.3s linear;
}

.diner-num-cot {
  width: 306px;
  padding: 25px 20px 15px;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -153px;
  margin-top: -160px;
  border-radius: 10px;
}
.title {
  font-size: 16px;
  line-height: 1;
  text-align: center;
  font-weight: bold;
  margin-bottom: 10px;
}

.cot {
  width: 36px;
  line-height: 16px;
  text-align: center;
  border: 1px solid #d8d8d8;
  font-size: 12px;
  margin: 0 auto;
  border-radius: 18px;
  padding: 10px 0;
}

.active {
  .cot {
    border-color: #ea454c;
    background: #ea454c;
    color: white;
  }
}

.confirm {
  width: 160px;
  line-height: 20px;
  text-align: center;
  background: #ea454c;
  margin: 10px auto 0;
  display: block;
  border: none;
  padding: 10px 0;
  font-size: 14px;
  color: white;
  border-radius: 20px;
}
</style>