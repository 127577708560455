<template>
  <div class="container member">
    <div class="cellbox">
      <div class="cellline">
        <router-link to="/storedProtocolInfo">
          <div class="title">大圣储值协议</div>
          <div class="info"></div>
          <div class="iconfont iconjiantou3-copy"></div>
        </router-link>
      </div>
    </div>
    <!-- dining-record -->
    <div class="dining-record" v-if="false">
      <router-link to="/member/bill-list">用餐记录</router-link>
    </div>
  </div>
</template>
  
<script>
import activeOrg from "@/store/sitedata/activeOrg";
import { Indicator } from "mint-ui";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
export default {
  name: "member",
  mixins: [activeOrg],
  data() {
    return {
      moment,
    };
  },
  computed: {
  },
  created() {

  },
  mounted() {

  },
  methods: {
    ...mapActions(["NeedMember", "NeedBusinessConfig"]),
  },
  watch: {
    
  },
};
</script>
<style lang="scss" scoped>
.cellbox {
  padding: 0 16px;
}

.cellline {
  background: #fff;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);
  margin: 10px auto;
  padding: 0 15px 0 20px;
  position: relative;
}

.cellline .title {
  line-height: 20px;
  padding: 15px 0;
}

.cellline .head {
  height: 80px;
  line-height: 80px;
  border-radius: 50%;
  width: 80px;
}

.cellline .info {
  position: absolute;
  right: 25px;
  height: 50px;
  line-height: 20px;
  padding: 15px 0;
  top: 0;
  color: #ffbb05;
}

.cellline .iconfont {
  position: absolute;
  right: 10px;
  top: 0px;
  line-height: 20px;
  padding: 15px 0;
  color: rgba(0, 0, 0, 0.3);
  font-size: 14px;
}

.fontyellow {
  color: #ffbb05;
}

.line {
  width: 100%;
  min-height: 30px;
  line-height: 30px;
  display: block;
}
</style>
<style lang="scss" scoped>
[v-cloak] {
  display: none !important;
}

.manageBtnBox {
  padding: 15px 15px 0;
  background: #f2f2f6;
}

.manageBtn {
  width: 100%;
  height: 44px;
  line-height: 44px;
  border-radius: 20px;
  text-align: center;
  color: #fff;
  font-size: 14px;
  background: #ff423e;
  box-shadow: 0px 0px 9.9px 0.1px rgba(255, 66, 62, 0.5);
}

$main: #ffbb05;
$red: #ff423e;
$border: #e6e6ea;
$grey: #777;
$glod: #b6986a;
$green: #09bb07;

@mixin border-radius($size) {
  -webkit-border-radius: $size;
  -moz-border-radius: $size;
  border-radius: $size;
}

@mixin transition {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.tag {
  font-size: 12px;
  color: #fff;
  display: inline-block;
  position: absolute;
  top: 100px;
  right: 20px;
}

/* diningrecord */
.diningrecord {
  padding: 10px 0;
  overflow: scroll;
  width: 100%;

  li {
    background: #fff;
    @include border-radius(5px);
    -webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
    padding: 10px 16px;
    position: relative;
  }

  .title {
    font-size: 14px;
    line-height: 1;

    .table {
      font-size: 12px;
      color: $grey;
      margin-left: 10px;
    }
  }

  .time {
    font-size: 12px;
    line-height: 1;
    color: $grey;
    margin-top: 12px;
  }

  .price {
    font-size: 12px;
    line-height: 1;
    color: $grey;
    margin-top: 10px;
  }

  .arrow {
    position: absolute;
    top: 12px;
    right: 15px;

    i {
      font-size: 12px;
      color: white;
    }
  }

  .eating,
  .gocomment,
  .commented {
    line-height: 20px;
    padding: 3px 10px;
    background: #ff423e;
    text-align: center;
    @include border-radius(13px);
    color: #fff;
    font-size: 12px;
    position: absolute;
    bottom: 30px;
    right: 15px;
    box-shadow: 0px 0px 9.9px 0.1px rgba(255, 66, 62, 0.5);
    display: block;
  }

  .gocomment {
    background: #ffbb05;
    box-shadow: 0px 0px 9.9px 0.1px rgba(255, 187, 5, 0.5);
  }

  .commented {
    background: #c9c9c9;
    box-shadow: none;
  }
}

/* diningrecord end */
/* banner */

.banner {
  height: 120px;
  margin-top: 15px;

  .mint-swipe {
    width: 100%;
    height: 100%;
  }

  .img {
    width: 100%;
    height: 120px;
    overflow: hidden;
    display: block;
    @include border-radius(5px);

    img {
      width: 100%;
      display: block;
    }
  }

  .bannerimgitem {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
}

/* banner end */
/* member-card-box */

.member-card-box {
  padding: 10px 16px;

  .member-card {
    width: 100%;
    height: 200px;
    padding: 15px 20px;
    background: #1e1f1f;
    position: relative;
    overflow: hidden;
    background-size: 100%;
    @include border-radius(10px);

    .member-id {
      font-size: 18px;
      color: $glod;
      position: absolute;
      right: 20px;
      bottom: 25px;
      font-weight: bold;
      text-align: right;
    }

    .img-box {
      margin-top: 30px;

      .img {
        width: 120px;

        img {
          width: 100%;
          display: block;
        }
      }

      .text {
        font-size: 10px;
        color: $glod;
        margin-top: 15px;
        letter-spacing: 3px;
      }
    }

    .code-box {
      position: absolute;
      right: 0;
      top: 0;

      .img {
        width: 120px;

        img {
          width: 100%;
        }
      }

      i {
        font-size: 36px;
        color: #4b4d4d;
        position: absolute;
        right: 15px;
        top: 20px;
      }
    }

    .data-box {
      position: absolute;
      bottom: 15px;
      left: 20px;
      right: 20px;

      li {
        width: 33.33%;
        float: left;
        text-align: center;
        line-height: 20px;
        text-align: center;

        &:nth-child(1) {
          text-align: left;
          padding-left: 10px;
        }

        &:nth-child(3) {
          text-align: right;
          padding-right: 10px;
        }
      }

      a {
        display: inline-block;
        text-align: center;
      }

      .num {
        font-size: 12px;
        color: #fff;
      }

      .text {
        font-size: 12px;
        color: #fff;
      }
    }
  }
}

/* member-card-box end */
/* member-level */

.member-level {
  .level-box {
    padding: 20px 10px 15px;
    position: relative;
    z-index: 2;
    border-bottom: 1px solid $border;

    .level-list {
      //margin: 0 20px;

      li {
        width: 20%;
        float: left;
        text-align: center;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          height: 3px;
          width: 100%;
          background: #a3a3a3;
          top: 19px;
          left: -50%;
          z-index: -1;
        }

        &:first-child::after {
          display: none;
        }
      }

      .item {
        display: inline-block;
      }

      .icon {
        width: 40px;
        height: 40px;
        padding: 8px;
        text-align: center;
        background: #a3a3a3;
        @include border-radius(50%);

        img {
          color: #fff;
        }
      }

      .text {
        font-size: 12px;
        text-align: center;
        line-height: 1;
        margin-top: 10px;
      }

      .active {
        .icon {
          background: $main;
        }

        &::after {
          background: $main;
        }
      }
    }

    .progress {
      height: 4px;
      position: absolute;
      left: 50px;
      right: 50px;
      top: 38px;
      background: #a3a3a3;
      z-index: -1;

      .bar {
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: $main;
        -webkit-border-radius: 0 2px 2px 0;
        -moz-border-radius: 0 2px 2px 0;
        border-radius: 0 2px 2px 0;
      }
    }

    .level-data {
      text-align: center;
      font-size: 12px;
      line-height: 1;
      margin-top: 15px;

      .brand {
        height: 16px;
        line-height: 16px;
        font-size: 10px;
        padding: 0 5px;
        background: $main;
        color: #fff;
        display: inline-block;
        @include border-radius(2px);
      }

      .text {
        display: inline-block;
        font-size: 12px;

        .num {
          color: $main;
        }
      }
    }
  }
}

/* member-level end */
/* level-equity */
.level-equity {
  padding: 0 10px;

  li {
    width: 20%;
    height: 60px;
    float: left;
    padding-top: 10px;
    text-align: center;
  }

  .img {
    img {
      width: 16px;
      display: inline-block;

      &:nth-child(2) {
        display: none;
      }
    }
  }

  .text {
    font-size: 10px;
    line-height: 1;
    margin-top: 5px;
  }

  .active {
    .img {
      img {
        &:nth-child(1) {
          display: none;
        }

        &:nth-child(2) {
          display: inline-block;
        }
      }
    }

    .text {
      color: $main;
    }
  }

  .equity-popup {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
    background: rgba(0, 0, 0, 0.5);

    .equity-popup-cot {
      width: 300px;
      padding: 30px 10px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -150px;
      margin-top: -150px;
      background: #fff;
      @include border-radius(10px);
      position: relative;

      .img {
        width: 100%;

        img {
          width: 100%;
          display: block;
        }
      }

      .title {
        font-size: 14px;
        line-height: 1;
        padding-left: 10px;
        margin: 10px 0;
        text-align: left;
        position: relative;

        &:before {
          content: "";
          width: 3px;
          height: 14px;
          background: $main;
          position: absolute;
          left: 0;
          top: 50%;
          margin-top: -7px;
          @include border-radius(2px);
        }
      }

      .int {
        text-align: left;
        font-size: 12px;
        line-height: 18px;
        color: $grey;
      }

      .close {
        width: 20px;
        height: 20px;
        position: absolute;
        top: 5px;
        right: 10px;

        img {
          width: 100%;
        }
      }
    }
  }
}

/* level-equity end */
/* dining-record */
.dining-record {
  padding: 10px;
  background: #f2f2f6;

  a {
    width: 100%;
    height: 45px;
    line-height: 45px;
    text-align: center;
    background: $main;
    display: block;
    font-size: 14px;
    @include border-radius(23px);
  }
}

/* dining-record end */
/* hot-exchange */
.hot-exchange {
  padding: 20px 16px;

  .title-box {
    height: 20px;

    .title {
      line-height: 1;
      font-size: 14px;
      float: left;

      i {
        color: #d81e06;
        font-size: 14px;
      }
    }

    a {
      display: block;
      float: right;
      font-size: 14px;
      color: $main;

      i {
        display: inline-block;
        font-size: 12px;
        color: $main;
      }
    }
  }

  .hot-exchange-cot {
    margin-top: 15px;

    .hot-exchange-list {
      li {
        margin-top: 10px;
      }

      a {
        padding: 8px;
        height: 106px;
        @include border-radius(5px);
        -webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);
        display: block;
        position: relative;
        overflow: hidden;
      }

      .img {
        width: 90px;
        height: 90px;
        overflow: hidden;
        @include border-radius(5px);
        float: left;
      }

      .text-box {
        margin-left: 100px;
        padding-top: 10px;

        .title {
          font-size: 14px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .num {
          font-size: 12px;
          line-height: 1;
          color: $grey;
          margin-top: 10px;
        }

        .spend {
          font-size: 12px;
          color: $red;
          line-height: 1;
          margin-top: 10px;
        }
      }

      .btn {
        width: 50px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        background: $main;

        .text {
          width: 50px;
          height: 100%;
          line-height: 50px;
          font-size: 12px;
          text-align: center;
          color: #fff;
          -webkit-writing-mode: vertical-rl;
          -ms-writing-mode: bt-rl;
          writing-mode: vertical-rl;
        }
      }
    }
  }
}

/* hot-exchange end */
/* activicity */
.activicity {
  padding: 0px 10px;
  background: #f2f2f6;

  .title-box {
    text-align: center;

    .title-cot {
      line-height: 1;
      display: inline-block;
      position: relative;
      padding: 0 40px;
      z-index: 2;

      &:after {
        content: "";
        width: 100%;
        height: 1px;
        background: #d0d0d0;
        position: absolute;
        top: 6px;
        left: 0;
        z-index: -1;
      }
    }

    .title {
      font-size: 14px;
      padding: 0 15px;
      line-height: 1;
      background: #f2f2f6;
    }
  }

  .img {
    overflow: hidden;
    // margin-top: 15px;
    @include border-radius(5px);

    img {
      width: 100%;
      display: block;
    }
  }

  .support {
    height: 35px;
    line-height: 35px;
    text-align: center;
    font-size: 14px;
    text-align: center;
    color: $grey;
  }
}

/* activicity end */
/* bar-code */
.bar-code {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  @include transition;

  .bar-code-cot {
    width: 300px;
    padding: 15px 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    background: #fff;
    @include border-radius(10px);
    margin-left: -150px;
    margin-top: -80px;
    z-index: 1;

    .title {
      font-size: 14px;
      text-align: center;
      line-height: 1;
    }

    .bar {
      width: 100%;
      margin: 15px 0;

      img {
        display: block;
      }
    }

    .text {
      text-align: center;
      line-height: 1;
      font-size: 12px;
    }
  }

  .bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
  }
}

/* bar-code end */
/*fade*/
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

/*fade*/
</style>
<style>
.member .line {
  width: 100%;
  min-height: 30px;
  line-height: 30px;
  display: block;
}
</style>