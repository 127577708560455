import Vue from 'vue'
import Router from 'vue-router'
// import Home from '@/components/Home'
// import Store from '@/components/store/Store'
// import Confirm from '@/components/order/confirm'
// import Order from '@/components/order/Order'
// import Search from '@/components/search/Search'
// import Pay from '@/components/pay/Pay'
// import Pay_coupon from '@/components/pay/coupon'
// import Pay_result from '@/components/pay/pay_result'
// import Myself from '@/components/myself/myself'
// import Diningrecord from '@/components/myself/diningrecord'
// import Showshop from '@/components/myself/showshop'
// import Shop from '@/components/myself/shop'
// import Score from '@/components/myself/score'
// import Consume from '@/components/myself/consume'
// import Coupon from '@/components/myself/coupon'
// import Excharge from '@/components/myself/excharge'
// import Comment2 from '@/components/myself/comment'
// import Showdining from '@/components/myself/showdining'
// import Showdiningunevaluated from '@/components/myself/showdining_unevaluated'
// import Myselfpay from '@/components/myself/pay'
// import Myselfpayresult from '@/components/myself/pay_result'
// import Myselforder from '@/components/myself/order'
// // import Exchargerecord from '@/components/myself/exchargerecord'
// import Showcoupon from '@/components/myself/showcoupon'


import Stores from '@/components/views/Stores'
import TimeOut from '@/components/views/ts/TimeOut'
import DishMenu from '@/components/views/ts/DishMenu'
import ShoppingCart from '@/components/views/ts/ShoppingCart'
import PutOK from '@/components/views/ts/PutOK'
import OrderInfo from '@/components/views/ts/OrderInfo'
import Check from '@/components/views/ts/Check'
import CheckPay from '@/components/views/ts/Pay'
import Comment from '@/components/views/member/comment/comment'
import OrderInfoOld from '@/components/views/ts/OrderInfoOld'

import MemberCenter from '@/components/views/member/Index'
import Bill_BillList from '@/components/views/member/Bill/BillList'
import Bill_BillDetail from '@/components/views/member/Bill/BillDetail'
import Card_CardList from '@/components/views/member/Card/CardList'
import Card_CardDetail from '@/components/views/member/Card/CardDetail'
import ReceiveCard from '@/components/views/member/Card/ReceiveCard'
import Integral_IntegralDetail from '@/components/views/member/Integral/IntegralDetail'
import Stored_StoredDetail from '@/components/views/member/Stored/StoredDetail'
import Stored_Recharge from '@/components/views/member/Stored/Recharge'
import IntegralShop from '@/components/views/member/Integral/IntegralShop'
import BindStep1 from '@/components/views/member/getBackMember/BindStep1'
import BindStep2 from '@/components/views/member/getBackMember/BindStep2'
import BindDetail from '@/components/views/member/getBackMember/BindDetail'
import FrozenPage from '@/components/views/member/modules/FrozenPage'

import GoodsShop from '@/components/views/member/Integral/GoodsShop'
import GoodsInfo from '@/components/views/member/Integral/GoodsInfo'
import GoodsPay from '@/components/views/member/Integral/GoodsPay'
import ExchargeRecord from '@/components/views/member/Integral/ExchargeRecord'

import MarketingOnCard from '@/components/views/Marketing/MarketingOnCard'


import PayMain from '@/components/views/modules/FC_Pay'
import PayT from '@/components/views/pay/Pay'
import PayState from '@/components/views/pay/PayState'


import storedProtocolInfo from '@/components/views/protocols/storedProtocolInfo'
import protocolsIndex from '@/components/views/protocols/Index'

import Invalid from '@/components/views/error/invalid'

import PayControlTest from '@/components/views/PayControlTest'
// import PhoneControl from '@/components/views/PhoneControl'


Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    { path: '/PayControlTest', name: 'PayControlTest', component: PayControlTest, meta: { title: '支付控件测试' } },
    // { path: '/PhoneControl', name: 'PhoneControl', component: PhoneControl, meta: { title: '拉取手机号控件' } },

    { path: '/2023/dishMenu', name: 'dishMenu2023', component: () => import('@/components/views/2023/dishMenu/dishMenu'), meta: { title: '在线点餐' } },
    { path: '/2023/shoppingCart', name: 'shoppingCart2023', component: () => import('@/components/views/2023/shoppingCart/shoppingCart'), meta: { title: '购物车确认' } },
    { path: '/2023/putOK', name: 'putOK2023', component: () => import('@/components/views/2023/putOK/putOK'), meta: { title: '提交成功' } },
    { path: '/2023/orderInfo', name: 'orderInfo2023', component: () => import('@/components/views/2023/orderInfo/orderInfo'), meta: { title: '消费详情' } },
    { path: '/2023/orderCheck', name: 'orderCheck2023', component: () => import('@/components/views/2023/orderCheck/orderCheck'), meta: { title: '买单' } },




    // { path: '/2023/storePay', name: 'storePay2023', component: () => import('@/components/views/2023/storePay/storePay'), meta: { title: '门店扫码支付' } },
    { path: '/2023/Pay', name: 'Pay2023', component: () => import('@/components/views/2023/pay/pay'), meta: { title: '门店支付' } },




    
    {
      path: '/pay/main',
      name: 'PayMain',
      component: PayMain,
      meta: {
        title: '支付'
      }
    }, {
      path: '/TimeOut',
      name: 'TimeOut',
      component: TimeOut,
      meta: {
        title: '欢迎光临'
      }
    }, {
      path: '/Stores',
      name: 'Stores',
      component: Stores,
      meta: {
        title: '寻找餐厅'
      }
    },
    {
      path: '/dish-menu',
      name: 'DishMenu',
      component: DishMenu,
      meta: {
        title: '在线点餐'
      }
    },
    {
      path: '/shopping-cart',
      name: 'ShoppingCart',
      component: ShoppingCart,
      meta: {
        title: '购物车确认'
      }
    },
    {
      path: '/PutOK',
      name: 'PutOK',
      component: PutOK,
      meta: {
        title: '提交成功'
      }
    },
    {
      path: '/order-info',
      name: 'OrderInfo',
      component: OrderInfo,
      meta: {
        title: '消费详情'
      }
    },
    {
      path: '/order-check',
      name: 'Check',
      component: Check,  
      meta: {
        title: '在线买单'
      }
    },
    {
      path: '/check-pay',
      name: 'CheckPay',
      component: CheckPay,
      meta: {
        title: '买单支付'
      }
    },
    {
      path: '/member/Integral/GoodsShop',
      name: 'GoodsShop',
      component: GoodsShop,
      meta: {
        title: '积分商城'
      }
    },
    {
      path: '/GoodsShop/GoodsInfo',
      name: 'GoodsInfo',
      component: GoodsInfo,
      meta: {
        title: '商品详情'
      }
    },
    {
      path: '/GoodsShop/GoodsPay',
      name: 'GoodsPay',
      component: GoodsPay,
      meta: {
        title: '商品支付'
      }
    },
    {
      path: '/GoodsShop/ExchargeRecord',
      name: 'ExchargeRecord',
      component: ExchargeRecord,
      meta: {
        title: '兑换记录'
      }
    },
    {
      path: '/member/comment',
      name: 'comment',
      component: Comment,
      meta: {
        title: '消费评价'
      }
    },





    {
      path: '/order-info-old',
      name: 'OrderInfoOld',
      component: OrderInfoOld,
      meta: {
        title: '消费详情'
      }
    },



    {
      path: '/member',
      name: 'MemberCenter',
      component: MemberCenter,
      meta: {
        title: '会员中心'
      }
    },
    {
      path: '/BindStep1',
      name: 'BindStep1',
      component: BindStep1,
      meta: {
        title: '找回余额'
      }
    },
    {
      path: '/BindStep2',
      name: 'BindStep2',
      component: BindStep2,
      meta: {
        title: '找回余额'
      }
    },
    {
      path: '/bindDetail',
      name: 'bindDetail',
      component: BindDetail,
      meta: {
        title: '实体卡详情'
      }
    },
    {
      path: '/member/bill-list',
      name: 'BillList',
      component: Bill_BillList,
      meta: {
        title: '历史消费'
      }
    },
    {
      path: '/member/bill-detail',
      name: 'BillDetail',
      component: Bill_BillDetail,
      meta: {
        title: '消费详情'
      }
    },
    {
      path: '/member/card-list',
      name: 'CardList',
      component: Card_CardList,
      meta: {
        title: '我的卡券'
      }
    },
    {
      path: '/member/card-list/card-detail',
      name: 'CardDetail',
      component: Card_CardDetail,
      meta: {
        title: '卡券详情'
      }
    },
    {
      path: '/receivecard/',
      name: 'ReceiveCard',
      component: ReceiveCard,
      meta: {
        title: '领取卡券'
      }
    },
    {



      path: '/MarketingOnCard/',
      name: 'MarketingOnCard',
      component: MarketingOnCard,
      meta: {
        title: '卡券活动'
      }
    },
    {
      path: '/member/Integral/IntegralDetail',
      name: 'IntegralDetail',
      component: Integral_IntegralDetail,
      meta: {
        title: '积分记录'
      }
    },
    {
      path: '/Integralshop/good-info',
      name: 'GoodsInfo',
      component: GoodsInfo,
      meta: {
        title: '商品详情'
      }
    },
    {
      path: '/member/stored-detail',
      name: 'StoredDetail',
      component: Stored_StoredDetail,
      meta: {
        title: '储值记录'
      }
    },
    {
      path: '/member/recharge',
      name: 'Recharge',
      component: Stored_Recharge,
      meta: {
        title: '储值充值'
      }
    },


    {
      path: '/pay-t',
      name: 'PayT',
      component: PayT,
      meta: {
        title: '储值充值'
      }
    },
    {
      path: '/pay/state',
      name: 'PayState',
      component: PayState,
      meta: {
        title: '储值充值'
      }
    },


    {
      path: '/storedProtocolInfo',
      name: 'storedProtocolInfo',
      component: storedProtocolInfo,
      meta: {
        title: '大圣储值服务协议'
      }
    },
    {
      path: '/protocols/index',
      name: 'protocolsIndex',
      component: protocolsIndex,
      meta: {
        title: '条款与协议'
      }
    },
    

    
    {
      path: '/invalid/',
      name: 'invalid',
      component: Invalid,
      meta: {
        title: '页面错误'
      }
    },
    {
      path: '/member/FrozenPage',
      name: 'FrozenPage',
      component: FrozenPage,
      meta: {
        title: '账号信息异常'
      }
    },
    
  ],
})