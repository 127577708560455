<template>
    <div style="padding: 16px 16px 100px;">
        <div style="text-align: right;">更新日期：2024年9月15日<br/>生效日期：2024年9月16日</div>
        <div class="title">储值协议</div>
        <div class="info">欢迎您使用大圣会员在线储值和购买预付卡功能，该协议由湖北初味品牌管理有限公司（以下简称“大圣”）制定。在您储值和购买预付卡前请务必仔细阅读该储值协议，如您不同意本储值协议，请及时停止储值和购买预付卡的操作，一旦您提交储值和完成购买预付卡操作（等同于已完成在线签字），即表示您同意并接受以下条款。为确保您的权益，请务必填写真实身份信息包括不限于姓名、身份证号、手机号。</div>
        <div class="title1">一、储值详情</div>
        <div class="title2">1、卡的种类:储值卡</div>
        <div class="title2">2、储值金额：人民币【根据您实际购买的金额确定】元，赠送金额：人民币【根据您实际购买的情况确定】元（如有）。</div>
        
        <div class="title1">二、储值方式以及储值使用</div>
        <div class="title2">1、您可以在大圣烧烤公众号内进行线上储值。储值后您可以通过大圣烧烤公众号【首页】-【会员中心】-【储值记录】页面查余额情况以及消费记录；</div>
        <div class="title2">2、您可以使用线上支付形式进行储值；也可以通过线下购买实体储值卡，购买完毕后绑定微信会员消费；</div>
        <div class="title2">3、您储值成功后，储值金额充值进会员账户余额，可以在大圣烧烤门店（其余储值及营销类活动规则见活动详情）验证会员信息后进行消费。如果使用的消费订单发生退款，根据实际储值支付金额减去优惠金额返还储值账户内；</div>
        <div class="title2">4、您可以在储值时选择对应的金额进行储值，储值一旦成功，我们将无法提供更改、修正服务。</div>
        
        <div class="title1">三、储值规则</div>
        <div class="title2">1、储值条件：储值仅限大圣烧烤会员参与，您可以通过登录大圣烧烤指定公众号，授权并同意《用户协议》及《隐私政策》，注册成为大圣会员；</div>
        <div class="title2">2、储值实名制要求：当您的账户余额大于100元，为了保障您的账户资金安全，大圣将收集您的实名信息，包括姓名、手机号等；</div>
        <div class="title2">储值权益和优惠：如您参与储值活动，如赠送代金券、优惠券、积分或金额等，当您完成指定金额储值时，对应的优惠权益将放至您的账户内。当您申请储值退款时，为您赠送的优惠权益未使用部分将回收，包括未使用的储值赠送金额等。具体储值优惠规则以您参与的当期储值活动规则为准。</div>

        <div class="title1">四、储值退款</div>
        <div class="title2">如您储值后申请退款，需要在【15:00-24:00期间】致电大圣烧烤官方客服电话400-027-8567进行退款。</div>

        <div class="title1">五、开具发票时间及规则</div>
        <div class="info">开具的发票为：增值税电子普通发票，在门店消费后按照当餐消费本金金额开具发票。</div>

        <div class="title1">六、违约责任</div>
        <div class="info">如因我们导致您的储值金额无法继续使用的，则我们将全额退还您会员账户中剩余储值本金（不含赠送金额）。</div>

        <div class="title1">七、修改资料</div>
        <div class="info">因会员储值账号需要绑定您的手机号，如果您更换手机号，请及时通过大圣烧烤公众号【会员中心】-【我的资料】-【手机号】完成修改。</div>

        <div class="title1">八、储值协议的生效及修订</div>
        <div class="title2">1、更新后最新版储值协议的发布及生效时间将以本协议顶部显示的日期为准；</div>
        <div class="title2">2、当您使用大圣的储值服务时，即视为您已经阅读理解并同意本协议的约束；</div>
        <div class="title2">3、大圣有权在必要时修订本协议。在该等情形下，我们将在指定的渠道公示修订后的协议内容并附生效日期；</div>
        <div class="title2">4、本协议条款变更后，如果您继续使用大圣提供的服务，即视为您已接受修改后的协议。如果您不接受修改后的协议，应当立即停止使用提供的服务。</div>

        <div class="title1">九、争议及解决</div>
        <div class="info">本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国法律。凡因本协议引起的任何纠纷争议或索赔，应友好协商解决，协商未果的，任何一方有权通过向发卡方所在管辖地有管辖权的法院（湖北省宜昌市西陵区法院）诉讼解决。</div>
        <div class="info"></div>
        <div class="info"></div>

        <div class="title1"></div>
        <div class="info"></div>
        <div class="info"></div>
        <div class="info"></div>
    </div>
</template>

<script>
    export default {
        name:'storedProtocolInfo'
    }
</script>

<style lang="scss" scoped>
    .title{text-align: center;font-size: 18px;font-weight: bold;line-height: 40px;}
    .title1{font-size: 16px;font-weight: bold;line-height: 40px;}
    .title2{text-indent: 1em;font-size: 14px;}
    .info{text-indent: 2em; line-height: 24px;font-size: 14px;}
    div{line-height: 24px;}
</style>